<template>
  <div
    class="unlocking-layer"
    :class="{
      active: layerActive,
      moved: layerMoved,
    }"
  >
    <letter-animation
      content="Unlocking the future"
      :active="letterAnimationActive"
    >
    </letter-animation>
  </div>
</template>

<script>
import LetterAnimation from './LetterAnimation.vue'

export default {
  name: 'UnlockingLayer',
  components: { LetterAnimation },
  emits: ['animation-finished'],
  data: () => ({
    letterAnimationActive: false,
    layerActive: false,
    layerMoved: false,
  }),
  mounted() {
    setTimeout(() => {
      this.layerActive = true
      this.letterAnimationActive = true
    })

    setTimeout(() => (this.layerMoved = true), 1600)
    setTimeout(() => this.$emit('animation-finished'), 2000)
  },
}
</script>

<style scoped>
.unlocking-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  pointer-events: none;
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.2s ease-out, transform 0.4s ease-out;
  will-change: opacity, transform;
}
.unlocking-layer.active {
  opacity: 1;
  pointer-events: all;
}
.unlocking-layer.moved {
  transform: translateX(100%);
}
</style>
