<template>
  <p class="text-3xl font-bold" ref="content"></p>
</template>

<script>
// animation time in milliseconds
const ANIMATION_TIME = 900

export default {
  name: 'LetterAnimation',
  props: ['content', 'active'],
  watch: {
    active(newValue) {
      if (newValue) {
        this.start()
      } else {
        this.$refs.content.innerHTML = ''
      }
    },
  },
  methods: {
    start() {
      const delay = ANIMATION_TIME / this.content.length
      for (let i = 0; i * delay < ANIMATION_TIME; i++) {
        setTimeout(
          () => (this.$refs.content.innerHTML += this.content.charAt(i)),
          i * delay
        )
      }
    },
  },
}
</script>
