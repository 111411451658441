<template>
  <div
    id="sidebar"
    class="bg-black fixed left-0 items-center z-40
    xl:w-16 xl:p-3 xl:pb-10
    lg:w-16 lg:p-3 lg:pb-10
    md:w-16 md:p-3 md:pb-10 md:flex md:flex-col
    hidden"
  >
    <router-link
      to="/"
      class="cursor-pointer
        xl:mb-8
        lg:mb-8
        md:mb-8"
    >
      <img class="h-10" src="@/assets/img/chapa.png" alt="Trends Lab" />
    </router-link>
    <a
      href="https://www.facebook.com/TrendsLabtl"
      target="_blank"
      class="cursor-pointer
        xl:w-2 xl:mb-8
        lg:w-2 lg:mb-8
        md:w-2 md:mb-8"
    >
      <img src="@/assets/img/facebook.svg" alt="Facebook" />
    </a>
    <a
      href="https://www.instagram.com/trendslab.tl/"
      target="_blank"
      class="cursor-pointer
        xl:w-4 xl:mb-8
        lg:w-4 lg:mb-8
        md:w-4 md:mb-8"
    >
      <img src="@/assets/img/instagram.svg" alt="Instagram" />
    </a>
    <a
      href="https://www.linkedin.com/company/trendslab/"
      target="_blank"
      class="cursor-pointer
        xl:w-4 xl:mb-8
        lg:w-4 lg:mb-8
        md:w-4 md:mb-8"
    >
      <img src="@/assets/img/linkedin.svg" alt="LinkedIn" />
    </a>
    <a
      href="https://open.spotify.com/user/31m6o7locle3orttalmw6nlcaffm?si=30a3d456e55849b5"
      target="_blank"
      class="cursor-pointer
        xl:w-4 xl:mb-8
        lg:w-4 lg:mb-8
        md:w-4 md:mb-8"
    >
      <img src="@/assets/img/spotify.svg" alt="Spotify" />
    </a>
    <a
      href="https://www.tiktok.com/@trendslab.tl?"
      target="_blank"
      class="cursor-pointer
        xl:w-4 xl:mb-8
        lg:w-4 lg:mb-8
        md:w-4 md:mb-8"
    >
      <img src="@/assets/img/tiktok.svg" alt="TikTok" />
    </a>
    <a
      href="https://wa.me/525630849290"
      target="_blank"
      class="cursor-pointer
        xl:w-4
        lg:w-4
        md:w-4"
    >
      <img src="@/assets/img/whatsapp.png" alt="Whatsapp" />
    </a>
  </div>
</template>

<style scoped>
#sidebar {
  max-height: 70px;
  overflow: hidden;
  transition: max-height 500ms ease;
  will-change: max-height;
  top: calc(50% - 168px);
}
#sidebar:hover {
  max-height: 368px;
}
</style>
