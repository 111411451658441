<template>
  <!-- Brand icon -->
  <router-link
    to="/"
    class="fixed mix-blend-difference z-1000
    md:top-10 md:left-10 md:h-4
    top-8 left-8 h-3"
  >
    <img src="@/assets/img/name-white.svg" class="h-full" alt="Trends Lab" />
  </router-link>

  <!-- i18n -->
  <button
    class="fixed mix-blend-difference text-white z-1000
    md:top-10 md:right-40
    top-7 right-36"
    @click="changeLocale()"
  >
    <span
      class="animation-underline"
      :class="{ active: currentLocale === 'en' }"
    >
      EN
    </span>
    |
    <span
      class="animation-underline"
      :class="{ active: currentLocale === 'es' }"
    >
      ES
    </span>
  </button>

  <!-- anvorgesa -->
  <span
    class="fixed flex items-center cursor-pointer mix-blend-difference text-white z-1000
    md:top-10 md:right-10
    top-7 right-8"
    @click="active = true"
  >
    <img src="@/assets/img/burger-white.svg" class="inline mr-4" alt="Menu" />
    Menu
  </span>

  <!-- Menu -->
  <div id="menu" class="menu" :class="{ active: showMenu }">
    <div class="menu-back" :class="{ active: showBack }">
      <button
        class="absolute top-4 right-8 p-4 text-base"
        @click="active = false"
      >
        <img src="@/assets/img/times.svg" alt="Close menu" />
      </button>
      <div class="flex flex-col min-h-screen">
        <nav
          class="flex-grow flex flex-col justify-center items-start uppercase
          md:p-16
          p-8"
        >
          <router-link
            class="menu-item enter-slide"
            :class="{ show: showText }"
            to="/about"
            @click="active = false"
          >
            <fill-animation></fill-animation>
          </router-link>
          <router-link
            class="menu-item enter-slide delay-100"
            :class="{ show: showText }"
            to="/services"
            @click="active = false"
          >
            <fill-animation asset="services"></fill-animation>
          </router-link>
          <router-link
            class="menu-item enter-slide delay-200"
            to="/contact"
            :class="{ show: showText }"
            @click="active = false"
          >
            <fill-animation asset="contact"></fill-animation>
          </router-link>
        </nav>
        <nav
          class="flex w-full enter-slide
          md:pb-16 md:px-16 md:flex-row md:items-center
          pb-12 px-8 flex-col items-start"
          :class="{ show: showText }"
        >
          <a
            href="https://www.facebook.com/TrendsLabtl"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >Facebook</a
          >
          <a
            href="https://www.instagram.com/trendslab.tl/"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >Instagram</a
          >
          <a
            href="https://www.linkedin.com/company/trendslab/"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >LinkedIn</a
          >
          <a
            href="https://open.spotify.com/user/31m6o7locle3orttalmw6nlcaffm?si=30a3d456e55849b5"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >Spotify</a
          >
          <a
            href="https://twitter.com/trendslab_tl"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >Twitter</a
          >
          <a
            href="https://www.tiktok.com/@trendslab.tl?"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >TikTok</a
          >
          <a
            href="https://wa.me/525630849290"
            target="_blank"
            class="font-semibold leading-none-important
            lg:text-xl lg:mr-8
            md:text-lg md:mr-4"
            >Whatsapp</a
          >
          <router-link
            to="/"
            class="flex-grow flex justify-end text-right h-5 p-1
            md:mt-0
            mt-8"
            :style="{ padding: '3px' }"
            @click="active = false"
          >
            <img src="@/assets/img/name.svg" class="h-full" alt="Trends Lab" />
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import FillAnimation from './FillAnimation.vue'

// Global state
import { store } from '../main'

export default {
  components: { FillAnimation },
  name: 'Header',
  data: () => ({
    active: false,

    showMenu: false,
    showBack: false,
    showText: false,

    currentLocale: 'en',
  }),
  watch: {
    active(nw) {
      document.body.style.overflow = this.active ? 'hidden' : 'auto'
      if (nw) {
        this.showMenu = true
        setTimeout(() => (this.showBack = true), 200)
        setTimeout(() => (this.showText = true), 700)
      } else {
        store.showUnlockingLayer()
        setTimeout(() => {
          this.showText = false
          this.showBack = false
          this.showMenu = false
        }, 500)
      }
    },
  },
  methods: {
    changeLocale() {
      this.currentLocale = this.currentLocale === 'en' ? 'es' : 'en'
      this.$i18n.locale = this.currentLocale
    },
  },
}
</script>

<style scoped>
.menu-item {
  font-size: 2.625rem;
  line-height: 5.25rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .menu-item {
    font-size: 5.625rem;
    line-height: 6.75rem;
  }
  .leading-none-important {
    line-height: 1 !important;
  }
}
@media (min-width: 1024px) {
  .menu-item {
    font-size: 7.5rem;
    line-height: 9rem;
  }
}
@media (min-width: 1440px) {
  .menu-item {
    font-size: 10.625rem;
    line-height: 12.625rem;
  }
}
.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  overflow-y: auto;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.4s ease;
  will-change: transform;
  z-index: 1050;
}
.menu.active {
  transform: scaleX(1);
}
.menu-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.4s ease;
  will-change: transform;
  z-index: 1050;
}
.menu-back.active {
  transform: scaleX(1);
}

.enter-slide {
  position: relative;
}
.enter-slide::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  background-color: white;
  transform: scaleX(1);
  transform-origin: center right;
  transition: transform 0.4s ease;
  transition-delay: inherit;
  will-change: transform;
}
.enter-slide.show::after {
  transform: scaleX(0);
}
.delay-600 {
  transition-delay: 600ms;
}

.z-1000 {
  z-index: 1000;
}
.animation-underline {
  position: relative;
}
.animation-underline::after {
  content: ' ';
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.2s ease-out;
  will-change: transform;
}
.animation-underline.active::after {
  transform: scaleX(1);
}
</style>
