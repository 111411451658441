import { createApp, reactive } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import i18n from './i18n'

// store

export const store = {
  state: reactive({
    unlockingLayer: false,
    footerContact: false,
    routerAnimationEnd: true,
    file: null
  }),

  showUnlockingLayer() {
    this.state.unlockingLayer = true
    this.state.routerAnimationEnd = false
  },
  clearUnlockingLayer() {
    this.state.unlockingLayer = false
    this.state.routerAnimationEnd = true    
  },

  setFooterContact(newValue) {
    this.state.footerContact = newValue
  },
  clearFooterContact() {
    this.state.footerContact = false
  },

  setRouterAnimationEnd(newValue) {
    this.state.routerAnimationEnd = newValue
  },
  clearRouterAnimationEnd() {
    this.state.routerAnimationEnd = true
  },

  // PDF viewer
  showFile(file) {
    this.state.file = file
  },
  hideFile() {
    this.state.file = null
  }
}

createApp(App).use(i18n).use(router).mount('#app')
