export default {
  "home": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El primer laboratorio de Tendencias e<br/>Innovación en México"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nosotros"])},
    "projectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de proyectos"])},
    "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajamos con marcas de élite"])},
    "trends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencias"])},
    "trendsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de Tendencias"])},
    "editorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editorial"])},
    "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones"])},
    "gotoReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver reportes anteriores"])},
    "newPhrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUESTRA <span class=\"font-semibold\">BASE DE DATOS</span> ALCANZA LOS +57M&nbsp;"])}
  },
  "reports": {
    "reports2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021"])}
    },
    "reports2022": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])}
    },
    "reports2023": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023"])}
    },
    "reports2024": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2024"])}
    },
    "revolucionInformacion": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revolución de la información"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la ciencia ficción a la realidad"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/revolucion-informacion.pdf"])}
    },
    "volume1_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 de marzo, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1.pdf"])}
    },
    "volume2_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 de junio, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume2.pdf"])}
    },
    "volume3_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 de septiembre, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 3"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume3.pdf"])}
    },
    "volume4_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noviembre 24, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 4"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume4.pdf"])}
    },
    "volume1_2022": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión ES"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1-2.pdf"])}
    },
    "volume1_2022_en": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión EN"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1-2-EN.pdf"])}
    },
    "volume2_2023": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión ES"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volumen2-2022.pdf"])}
    },
    "volume2_2023_en": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión EN"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volumen2-2022-EN.pdf"])}
    },
    "volume1_2024": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 de Octubre, 2024"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volumen1-2024.pdf"])}
    },
    "breaking": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero 28, 2022"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rompiendo los<br/>paradigmas de<br/>la expresión: lenguaje<br/>y nueva belleza"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/breaking.pdf"])}
    },
    "luxuryGaming": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septiembre 13, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury gaming:<br/>e-gaming y metaverso"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/luxury-gaming.pdf"])}
    },
    "theFutureOfLuxuryBrands": {
      "date": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Revista Neo"]), _normalize(["Enero 28, 2022"])])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Futuro de las Marcas de Lujo y el Metaverso"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/the-future-of-luxury-brands.pdf"])}
    },
    "luxuryBrands": {
      "date": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["El Financiero"]), _normalize(["Junio 21, 2022"])])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcas de lujo, los nuevos jugadores en la contienda de los egames"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/luxury-brands.pdf"])}
    },
    "elMundo": {
      "date": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Magazine"]), _normalize(["October 23, 2023"])])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL MUNDO DESDE DIFERENTES PERSPECTIVAS"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/el-mundo-desde-diferentes-perspectivas.pdf"])}
    },
    "badHombre": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVISTA BADHOMBRE"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://badhombremagazine.com/?s=trends+lab"])}
    },
    "shockvertising": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shockvertising:<br/>Es toda la publicidad<br/>buena publicidad?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/shockvertising.pdf"])}
    },
    "emotions": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeros, emociones y comportamientos"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/emotions.pdf"])}
    },
    "artificialIntelligence": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inteligencia artificial: ¿la solución a la crisis creativa?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/artificial-intelligence.pdf"])}
    },
    "shortage": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febrero, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escasez: la tendencia para crear hype"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/shortage.pdf"])}
    },
    "emotionsAi": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emociones: el combustible de las inteligencias artificales."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/emotions-ai.pdf"])}
    },
    "goldenAgeOfSports": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿La era dorada de los deportes?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/golden-age-of-sports.pdf"])}
    },
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer más"])}
  },
  "footer": {
    "stayConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantente conectado"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "mainOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oficina principal"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C. Artículo 123 Nº 116, dept 104, Col. Centro, 06040 Ciudad de México."])},
    "hear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres escuchar algo diferente?"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síguenos"])},
    "allRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los derechos reservados"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajo"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])}
  },
  "about": {
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nosotros"])},
    "theFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-extrabold\">Trends Lab</span> es el primer laboratorio de tendencias en México, que ofrece una forma de inspiración basada en hechos."])},
    "atTrendsLab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Trends Lab desarrollamos la innovación a partir de <span class=\"font-normal\">detectar tendencias a punto de surgir</span>, nuevos agentes de cambio que serán transformados en <span class=\"font-normal\">conceptos e ideas disruptivas para impulsar oportunidades de negocio</span> y ventaja competitiva. Nosotros somos un laboratorio multidisciplinario que desarrolla tendencias<br/>e innovacion."])},
    "getInTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponerse en contacto"])},
    "trendsTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo TRENDS"])},
    "ceoFounder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO y fundador"])},
    "chiefIntelligence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directora de Investigación"])},
    "designMaterialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño y materialización"])},
    "artDirector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directora artística"])},
    "publicRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaciones públicas"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redacción y correción"])},
    "sociologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socióloga"])},
    "fashionTrends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencias de moda"])}
  },
  "services": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "digestReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de tendencia."])},
    "provide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provee una fuente de inspiracion e informacion para <span class=\"font-medium\">la creación de estrategias de mercado, así como entendimiento de hábitos futuros.</span>"])},
    "trendsForecasting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronóstico de Tendencias"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultoría y<br/>reportes personalizados:"])},
    "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tendencias + Conceptos) * Ideas =<br/><span class=\"font-bold\">Oportunidades de negocio.</span>"])},
    "theObjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objetivo es detectar nuevas tendencias y nuevos grupos de usuarios para marcas y aplicarlos a <span class=\"font-semibold\">modelos de negocio para crear ventaja competitiva e innovación.</span>"])},
    "weWill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajaremos juntos para identificar las siguientes oportunidades de crecimiento aprovechando nuestros <span class=\"font-semibold\">cinco laboratorios y nuestro equipo multidisciplinario.</span>"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-extrabold\">Checa</span> las tendencias más actuales."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "mainOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oficina Principal"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C. Artículo 123 Nº 116, dept 104, Col. Centro, 06040 Ciudad de México."])}
  }
}