import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },{
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },{
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },{
    path: '/reports/:id',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
  },{
    path: '/reports/:id/:file',
    name: 'ReportsFile',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
  },{ 
    path: '/:file', 
    name: 'HomeFile', 
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((newRoute, oldRoute) => {
  const newRouteName = newRoute.matched[0]?.name || ''
  const oldRouteName = oldRoute.matched[0]?.name || ''
  if (newRouteName.startsWith(oldRouteName) || oldRouteName.startsWith(newRouteName)) {
    return
  }
  window.scrollTo(0, 0)
})

export default router
