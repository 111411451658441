<template>
  <div class="viewer" :class="{ active: show }" @click="$emit('hide')">
    <iframe class="viewer-iframe" :src="pdf" frameborder="0"></iframe>
    <button class="viewer-close" @click="$emit('hide')">
      &times;
      <!-- <img src="@/assets/img/times-white.svg" alt="Close viewer"> -->
    </button>
  </div>
</template>

<script>
export default {
  name: 'PdfReader',
  props: ['pdf', 'show'],
  emits: ['hide'],
  computed: {
    origin() {
      return window.location.origin
    }
  },
  watch: {
    show(nw) {
      if (nw) {
        document.body.style.setProperty('overflow', 'hidden')
      } else {
        document.body.style.setProperty('overflow', 'auto')
      }
    },
  },
}
</script>

<style scoped>
.viewer {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}
.viewer.active {
  display: block;
}
.viewer-iframe {
  height: 100%;
  width: 100%;
}
.viewer-close {
  z-index: 1000;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  line-height: 1rem;
  cursor: pointer;
  mix-blend-mode: difference;
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

@media (min-width: 768px) {
  .viewer {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .viewer-close {
    top: 1rem;
    right: 2rem;
    padding: 1rem;
  }
}
</style>
