<template>
  <div
    ref="container"
    class="animation-container"
    :class="[`asset-${asset || 'about'}`, $i18n.locale]"
    @mouseenter="showFill()"
    @mouseleave="hideFill()"
  >
    <div ref="fill" class="animation-fill"></div>
    <!--<div class="animation-layer"
      :style="{
        background: `url(${assetImage})`,
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': '100% 100%'
      }"></div>-->
    <img :src="assetImage" class="animation-img" />
  </div>
</template>

<script>
import about from '../assets/img/_about.svg'
import services from '../assets/img/_services.svg'
import contact from '../assets/img/_contact.svg'

import nosotros from '../assets/img/_nosotros.svg'
import servicios from '../assets/img/_servicios.svg'
import contacto from '../assets/img/_contacto.svg'

export default {
  name: 'FillAnimation',
  props: ['asset'],
  computed: {
    assetImage() {
      switch (this.asset) {
        case 'contact':
          return this.$i18n.locale === 'es' ? contacto : contact
        case 'services':
          return this.$i18n.locale === 'es' ? servicios : services
        default:
          return this.$i18n.locale === 'es' ? nosotros : about
      }
    },
  },
  methods: {
    showFill() {
      this.$refs.fill.style.setProperty('transition-duration', '300ms')
      this.$refs.fill.classList.add('active')
    },
    hideFill() {
      this.$refs.fill.style.setProperty('transition-duration', '800ms')
      this.$refs.fill.classList.remove('active')
    },
  },
}
</script>

<style scoped>
.animation-container {
  height: 40px;
  display: inline-block;
  position: relative;
}

/* specific assets dimentions */
.asset-about {
  width: 188px;
}
.asset-services {
  width: 260px;
}
.asset-contact {
  width: 256px;
}

.asset-about.es {
  width: 307px;
}
.asset-services.es {
  width: 283px;
}
.asset-contact.es {
  width: 295px;
}

.animation-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.animation-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  object-fit: cover;
}
.animation-fill {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: 1005;
  background-color: black;
  transform: scaleX(1);
  transform-origin: center left;
  /*transition: transform 0.2s ease-out;*/
  transition-property: transform;
  transition-timing-function: ease-out;
  will-change: transform;
}
.animation-fill.active {
  transform: scaleX(1);
}

@media (min-width: 768px) {
  .animation-container {
    height: 65px;
  }
  .animation-fill {
    width: calc(100% - 3px);
    height: calc(100% - 4px);
  }
  .asset-about {
    width: 326px;
  }
  .asset-services {
    width: 417px;
  }
  .asset-contact {
    width: 416px;
  }

  .asset-about.es {
    width: 491px;
  }
  .asset-services.es {
    width: 459px;
  }
  .asset-contact.es {
    width: 477px;
  }
}
@media (min-width: 1024px) {
  .animation-fill {
    transform: scaleX(0);
  }
  .animation-container {
    height: 88px;
  }
  .asset-about {
    width: 441px;
  }
  .asset-services {
    width: 565px;
  }
  .asset-contact {
    width: 564px;
  }

  .asset-about.es {
    width: 667px;
  }
  .asset-services.es {
    width: 623px;
  }
  .asset-contact.es {
    width: 646px;
  }
}
@media (min-width: 1440px) {
  .animation-container {
    height: 122px;
  }
  .asset-about {
    width: 612px;
  }
  .asset-services {
    width: 783px;
  }
  .asset-contact {
    width: 782px;
  }

  .asset-about.es {
    width: 923px;
  }
  .asset-services.es {
    width: 870px;
  }
  .asset-contact.es {
    width: 901px;
  }
}
</style>
