<template>
  <my-header></my-header>
  <sidebar></sidebar>
  <div class="bg-white">
    <router-view />
  </div>
  <my-footer></my-footer>

  <!-- unlocking layer -->
  <unlocking-layer
    v-if="sharedState.unlockingLayer"
    @animation-finished="clearUnlockingLayer"
  >
  </unlocking-layer>

  <!-- PDFReader -->
  <PdfReader
    :pdf="sharedState.file"
    :show="!!sharedState.file"
    @hide="hideFile()"
  >
  </PdfReader>

  <!-- Navigation button (for mobiles) -->
  <img
    src="@/assets/img/arrow-down.svg"
    alt="Follow scroll"
    class="navigation-button"
    @click="gotoTop()"
  />
</template>

<script>
// Components
import Sidebar from './components/Sidebar.vue'
import MyHeader from './components/MyHeader.vue'
import MyFooter from './components/MyFooter.vue'
import UnlockingLayer from './components/UnlockingLayer.vue'
import PdfReader from './components/PdfReader.vue'

// Global state
import { store } from './main'

export default {
  name: 'App',
  components: { MyHeader, MyFooter, Sidebar, UnlockingLayer, PdfReader },
  data: () => ({
    loading: true,
    sharedState: store.state,
  }),
  methods: {
    clearUnlockingLayer() {
      store.clearUnlockingLayer()
    },
    hideFile() {
      store.hideFile()
    },
    gotoTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  },
  mounted() {
    document.getElementById('splash-layer').classList.add('active')
    setTimeout(() => {
      document.querySelector('.splash').classList.add('inactive')
      document.body.style.setProperty('overflow', 'auto')
    }, 400)
  },
}
</script>

<style>
.delimiter {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
}
.navigation-button {
  z-index: 1000;
  position: fixed;
  width: 32px;
  height: 32px;
  bottom: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  transform: rotate(180deg);
  mix-blend-mode: difference;
}
</style>
